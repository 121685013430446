<template>
  <div>
    <b-alert
      class="alert-fixed fixed-top rounded-1 m-4 bg-light"
      :show="dismissCountDown"
      dismissible
      variant="success"
      fade
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
    >
      <p class="float-end">{{ dismissCountDown }} วินาที...</p>
      <p>แจ้งเตือน</p>
      <hr />
      <p>{{ resalert }}</p>
    </b-alert>
    <div class="table-responsive mb-0">
      <b-table
        :items="document"
        :fields="fields"
        responsive="sm"
        :dataImg="dataImg"
        :dataCheck="dataCheck"
        :dataShow="dataShow"


      >
        <template v-for="(_, action) of $scopedSlots" v-slot:[action]="scope">
          <slot :name="action" v-bind="scope" />
        </template>
        <template #cell(index)="document">
          {{ document.index + 1 }}
        </template>
        <template #cell(fileId)="document">
          <div class="col-md-12">
            <ul class="list-inline mb-0">
              <li class="list-inline-item">
                <a
                  v-if="document.item.fileId != null"
                  class="px-2 text-info"
                  v-b-tooltip.hover
                  @click="getDataloadImage(document.item)"
                >
                  <i class="uil-paperclip font-size-18"></i>
                </a>
              </li>
            </ul>
          </div>
        </template>
        <template #cell(isReceived)="document">
          <div class="form-check form-switch">
            <input
              :disabled="document.item.isReceived !== null"
              v-model="document.item.isReceived"
              class="form-check-input"
              type="checkbox"
              id="flexSwitchCheckDefault"
              @change="putCheck(document.item)"
            />
          </div>
        </template>
      </b-table>
    </div>
    <b-modal
      ref="modalImg"
      id="modalImg"
      title="ภาพประกอบ"
      hide-footer
      size="l"
      centered
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-12 align-items-center">
            <div class="row align-items-center">
              <img
                :src="this.imagePath"
                class="preview-img"
                @click="singleImage = true"
              />
              <vue-easy-lightbox
                :visible="singleImage"
                :imgs="this.imagePath"
                @hide="singleImage = false"
              ></vue-easy-lightbox>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
export default {
  name: "documentComponent",
  props: {
    // fields: Array,
    // items: Array,
    dataImg: String,
    dataCheck: String,
    dataShow: String,
    // receivedBy:String,
    // isReceived:String
  },
  data() {
    return {
      selected: [],
      overlayFlag: false,
      regJobId: this.$route.params.regJobId,
      singleImage: false,
      imagePath: "",
      dismissSecs: 3,
      resalert: "",
      dismissCountDown: 0,
      document: [],

      fields: [
        {
          key: "index",
          label: "ลำดับ",
        },
        {
          key: "docTypeNameTh",
          label: "ประเภทเอกสาร",
        },
        {
          key: "fileId",
          label: "รูปภาพ/ไฟล์",
        },
        {
          key: "isReceived",
          label: "รับเอกสาร",
        },
        {
          key: "receivedDate",
          label: "วันที่",
        },

        {
          key: "receivedBy",
          label: "ผู้รับ",
        },
      ],
    };
  },
  created() {
    this.getDataShow();
  },
  mounted() {
  },
  computed: {
    tableHeader() {
      return this.fields || [];
    },
    tableData() {
      return this.items || [];
    },
  },

  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(response) {
      this.dismissCountDown = this.dismissSecs;
      this.resalert = response;
    },
    getDataloadImage: function (data) {
      this.fileId = data.fileId;
      useNetw
        .get(this.dataImg, {
          params: {
            regJobId: atob(this.regJobId),
            fileId: this.fileId,
          },
        })
        .then((response) => {
          this.rowImg = response.data.data;
          this.imagePath = response.data.data.filePath;
          this.$refs["modalImg"].show();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          // this.loading = false; //skeleton false
        });
    },
    putCheck(docId) {
      useNetw
        .put(this.dataCheck, {
          docId: docId.docId,
        })

        .then((response) => {
          this.getDataShow();
          this.showAlert(response.data.message);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$router.push({ name: "purchase-order" });
          // this.overlayFlag = false;
        });
    },
    getDataShow: function () {
      this.overlayFlag = true;
      useNetw
        .get(this.dataShow, {
          params: {
            regJobId: atob(this.regJobId),
          },
        })
        .then((response) => {
          this.document = response.data.data.document;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message + "<br>" + err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
  },
};
</script>
 
 
<style lang="scss" scoped>
.preview-img {
  max-width: 200px;
}
@media only screen and (min-width: 500px) {
  .alert-fixed {
    position: fixed;
    top: 50px;
    left: 65%;
    width: 30%;
    z-index: 9999;
    border-radius: 0px;
  }
}
</style>
 
