<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <b-alert
          class="alert-fixed fixed-top rounded-1 m-4 bg-light"
          :show="dismissCountDown"
          dismissible
          variant="success"
          fade
          @dismissed="dismissCountDown = 0"
          @dismiss-count-down="countDownChanged"
        >
          <p class="float-end">{{ dismissCountDown }} วินาที...</p>
          <p>แจ้งเตือน</p>
          <hr />
          <p>{{ resalert }}</p>
        </b-alert>
        <div class="card">
          <b-overlay
            :show="overlayFlag"
            :spinner-variant="this.$overlayVariant"
            :spinner-type="this.$overlayType"
            :rounded="this.$overlayRounded"
          >
            <div class="card-body">
              <!-- skeleton  -->
              <div class="row">
                <div class="col-6">
                  <p class="card-title-desc">รายละเอียดเอกสาร</p>
                </div>
                <div class="col-6 float-end">
                  <p
                    class="badge bg-success font-size-14 float-end"
                    v-if="this.form.status === 'SUBMIT'"
                  >
                    ยืนยันแล้ว
                  </p>
                  <p
                    class="badge bg-warning font-size-14 float-end"
                    v-if="this.form.status === 'DFT'"
                  >
                    ร่างเอกสาร
                  </p>
                </div>
              </div>
              <form class="needs-validation" @submit.prevent="formSend">
                <div class="row">
                  <div class="col-sm-12 col-md-12 align-items-center">
                    <div class="row align-items-center">
                      <div class="col-sm-6 col-md-3">
                        <div class="mb-3 position-relative">
                          <label for="branchId">สาขา:</label>

                          <multiselect
                            disabled
                            id="branchId"
                            v-model="form.branchId"
                            :options="localData"
                            class=""
                            label="nameTh"
                            :show-labels="false"
                            track-by="nameTh"
                            @input="handleSearch"
                          ></multiselect>
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-6">
                        <div class="mb-3 position-relative">
                          <label for="vin">เลขที่เอกสาร:</label>

                          <b-form-input
                            disabled
                            id="vin"
                            v-model="form.regJobCode"
                            type="text"
                            class="form-control"
                            value="Otto"
                          ></b-form-input>
                        </div>
                      </div>

                      <div class="col-md-2 col-sm-6">
                        <div class="mb-3 position-relative">
                          <label for="reqDate"> วันที่:</label>

                          <date-picker
                            disabled
                            v-model="form.sendDate"
                            :first-day-of-week="1"
                            format="YYYY-MM-DD"
                            value-type="format"
                            lang="en"
                            :class="{
                              'is-invalid':
                                submitform && $v.form.sendDate.$error,
                            }"
                          ></date-picker>
                          <div
                            v-if="submitform && $v.form.sendDate.$error"
                            class="invalid-tooltip"
                          >
                            <span v-if="!$v.form.sendDate.required">{{
                              message
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-sm-6">
                        <div class="row align-items-center">
                          <div class="col-md-12 col-sm-12 col-12">
                            <div class="mb-3 position-relative">
                              <label for="regCode">
                                เลือกจากเลขที่ใบแจ้งจำหน่าย:</label
                              >
                              <b-form-input
                                disabled
                                id="vin"
                                v-model="form.regCode"
                                type="text"
                                class="form-control"
                                value="Otto"
                              ></b-form-input>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-6">
                        <div class="row align-items-center">
                          <div class="col-sm-12 col-md-12">
                            <div class="mb-3 position-relative">
                              <label for="customerNameTh">ชื่อลูกค้า:</label>
                              <b-form-input
                                disabled
                                id="vin"
                                v-model="form.customerFullNameTh"
                                type="text"
                                class="form-control"
                                value="Otto"
                              ></b-form-input>
                            </div>
                          </div>
                          <!-- <div class="col-md-3 col-sm-3 col-3">
                          <div class="mt-2 position-relative">
                            <b-button
                              class="btn"
                              variant="info"
                              v-b-modal.modalReg
                            >
                              <i class="uil-file-search-alt"></i
                            ></b-button>
                          </div>
                        </div> -->
                        </div>
                      </div>

                      <div class="col-md-3 col-sm-6">
                        <div class="row align-items-center">
                          <div class="col-12 col-md-12 col-sm-12">
                            <div class="mb-3 position-relative">
                              <label for="vehicleId">ทะเบียนรถ:</label>
                              <b-form-input
                                disabled
                                id="vin"
                                v-model="form.vehicleId"
                                type="text"
                                class="form-control"
                                value="Otto"
                              ></b-form-input>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 col-md-3 col-sm-6">
                        <div class="mb-3 position-relative">
                          <label for="vin">เลขตัวถัง:</label>

                          <b-form-input
                            disabled
                            id="vin"
                            v-model="form.vin"
                            type="text"
                            class="form-control"
                            value="Otto"
                          ></b-form-input>
                        </div>
                      </div>

                      <div class="col-6 col-md-3 col-sm-6">
                        <div class="mb-3 position-relative">
                          <label for="modelNameTh">รุ่น:</label>

                          <b-form-input
                            disabled
                            id="modelNameTh"
                            v-model="form.modelNameTh"
                            type="text"
                            class="form-control"
                            value="Otto"
                          ></b-form-input>
                        </div>
                      </div>

                      <div class="col-md-3 col-sm-6">
                        <div class="row align-items-center">
                          <div class="col-12 col-md-12 col-sm-12">
                            <div class="mb-3 position-relative">
                              <label for="paymentId"
                                >เลขที่ใบเสร็จรับเงิน:</label
                              >
                              <b-form-input
                                disabled
                                id="vin"
                                v-model="form.paymentId"
                                type="text"
                                class="form-control"
                                value="Otto"
                              ></b-form-input>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-4 col-sm-4">
                        <div class="mb-2">
                          <label class="form-label d-block mb-3"></label>
                          <b-form-radio-group
                            disabled
                            v-model="form.isSale"
                            :options="optionSale"
                            name="radios-stacked"
                            stacked
                            :class="{
                              'is-invalid': submitform && $v.form.isSale.$error,
                            }"
                          >
                          </b-form-radio-group>
                          <div
                            v-if="submitform && $v.form.isSale.$error"
                            class="invalid-tooltip"
                          >
                            <span v-if="!$v.form.isSale.required">{{
                              message
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-5">
                        <div class="mb-3 position-relative">
                          <label for="note">หมายเหตุ:</label>
                          <!-- disabled="disabled"  -->
                          <textarea
                            disabled
                            v-model="form.note"
                            class="form-control"
                            name="textarea"
                          ></textarea>
                        </div>
                      </div>
                    </div>

                    <br />
                  </div>
                </div>
              </form>
            </div>
          </b-overlay>
        </div>

        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <p class="card-title-desc">รายการเอกสาร</p>
              </div>
            </div>
            <b-skeleton-wrapper :loading="loading">
              <template #loading>
                <b-skeleton-table
                  :rows="5"
                  :columns="6"
                  :table-props="{ bordered: false, striped: true }"
                  animation="throb"
                ></b-skeleton-table>
              </template>
              <document-component
                :dataImg="apiImg"
                :dataCheck="apiCheck"
                :dataShow="apiShow"
                :receivedBy="receivedBy"
                :isReceived="isReceived"
              >
              </document-component>
              <!-- Table -->
            </b-skeleton-wrapper>
            <hr />
            <div class="row mb-4 mt-4">
              <div class="col text-end ms-1">
                <span><code>*** กรุณาระบุรับเอกสารให้ครบก่อนยืนยัน</code></span
                ><br />
                <button class="btn btn-danger ms-1" @click="alertSubmit()">
                  ยืนยันการส่งเอกสาร
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end card -->
    </div>
    <b-modal
      ref="modalDoc"
      id="modalDoc"
      title="รายการเอกสารที่ได้รับจากลูกค้า"
      hide-footer
      size="xl"
      centered
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-12 align-items-center">
            <div class="row align-items-center">
              <div class="col-sm-4 col-md-4">
                <div class="mb-3 position-relative">
                  <label class="d-inline align-items-center"> สาขา:</label>
                  <multiselect
                    v-model="form.branchId"
                    :options="localData"
                    label="nameTh"
                    :show-labels="false"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="col-sm-4 col-md-4">
                <label class="d-inline align-items-center m-2 text-end">
                  เลขที่เอกสาร:
                  <b-form-input
                    v-model="filter.regCode"
                    type="search"
                    placeholder="เลขที่เอกสาร"
                    class="form-control ms-2"
                    @keyup.enter="handleSearch"
                  ></b-form-input>
                </label>
              </div>

              <div class="col-sm-4 col-md-4">
                <label class="d-inline align-items-center m-2">
                  ชื่อ-นามสกุลลูกค้า:
                  <b-form-input
                    v-model="filter.customerNameTh"
                    type="search"
                    placeholder="ชื่อ-นามสกุล"
                    class="form-control ms-2"
                    @keyup.enter="handleSearch"
                  ></b-form-input>
                </label>
              </div>

              <div class="col-md-3 col-sm-4">
                <div class="mb-3 position-relative">
                  <label class="d-inline align-items-center m-2">
                    จากวันที่:</label
                  >
                  <!-- disabled="disabled"  -->
                  <date-picker
                    v-model="filter.startDate"
                    :first-day-of-week="1"
                    format="YYYY-MM-DD"
                    value-type="format"
                    lang="en"
                  ></date-picker>
                </div>
              </div>
              <div class="col-md-3 col-sm-4">
                <div class="mb-3 position-relative">
                  <label class="d-inline align-items-center m-2">
                    ถึงวันที่:</label
                  >
                  <!-- disabled="disabled"  -->
                  <date-picker
                    v-model="filter.endDate"
                    :first-day-of-week="1"
                    format="YYYY-MM-DD"
                    value-type="format"
                    lang="en"
                  ></date-picker>
                </div>
              </div>
              <div class="col-sm-4 col-md-3">
                <label class="d-inline align-items-center m-2 text-end">
                  ทะเบียนรถ:
                  <b-form-input
                    v-model="filter.licensePlate"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control"
                  ></b-form-input>
                </label>
              </div>

              <div
                class="col-2 col-sm-2 col-md-2 text-end"
                style="margin-left: auto; margin-right: 0"
              >
                <b-button
                  class="btn btn ms-2"
                  variant="info"
                  type="submit"
                  @click="handleSearch"
                >
                  <i class="uil-search"></i>
                  ค้นหา
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton-table
              :rows="5"
              :columns="6"
              :table-props="{ bordered: false, striped: true }"
              animation="throb"
            ></b-skeleton-table>
          </template>
          <div class="row mt-2">
            <!-- Search -->
            <div class="col-sm-12 col-md-12">
              <div class="row align-items-center">
                <div class="col-sm-5 col-md-3">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-block align-items-center">
                      แสดงผล
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                        @input="handlePageChange"
                      ></b-form-select
                      >&nbsp; รายการ
                    </label>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6"></div>
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->

          <!--  v-if="this.veh.name === 'เจ้าของรถ'" -->
          <div class="table-responsive mb-0">
            <b-table
              :items="rowDocCus"
              :fields="fields"
              responsive="true"
              :per-page="perPage"
              :current-page="1"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
            >
              <template v-slot:cell(index)="rowDocCus">
                <b-form-checkbox v-model="chkDoc" :value="rowDocCus.item.docId">
                </b-form-checkbox>
              </template>
              <template #cell(brcusDate)="rowDocCus">
                <changeDate :date="rowDocCus.item.brcusDate"></changeDate>
              </template>
              <template #cell(status)="rowDocCus">
                <span v-if="rowDocCus.item.status === 'BRCUS'">
                  สาขารับจากลูกค้าแล้ว
                </span>
                <span v-if="rowDocCus.item.status === 'BSRGD'">
                  สาขาส่งให้แผนกทะเบียนแล้ว
                </span>
                <span v-if="rowDocCus.item.status === 'RGDRB'">
                  แผนกทะเบียนรับจากสาขาแล้ว
                </span>
                <span v-if="rowDocCus.item.status === 'RGDSM'">
                  แผนกทะเบียนส่งให้ผู้เดินทะเบียนแล้ว
                </span>
                <span v-if="rowDocCus.item.status === 'RGDRM'">
                  แผนกทะเบียนรับจากผู้เดินทะเบียนแล้ว
                </span>
                <span v-if="rowDocCus.item.status === 'RGDSB'">
                  แผนกทะเบียนส่งคืนสาขาแล้ว
                </span>
                <span v-if="rowDocCus.item.status === 'BRRGD'">
                  สาขารับจากแผนกทะเบียนแล้ว
                </span>
                <span v-if="rowDocCus.item.status === 'BSCUS'">
                  สาขาส่งคืนลูกค้าแล้ว
                </span>
              </template>

              <!-- End edit table -->
            </b-table>
          </div>

          <div class="row">
            <span v-if="this.totalRecord === 0" class="text-center font-size-16"
              >ไม่พบรายการ</span
            >
            <div class="col" v-if="this.totalRecord > 0">
              <div class="col">
                หน้า {{ currentPage }} จาก {{ this.totalPage }} ทั้งหมด
                {{ totalRecord }} รายการ
              </div>

              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRecord"
                      :per-page="perPage"
                      @change="handleChangePage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
            <br />
            <hr />
            <br />
          </div>
          <!-- </div> -->
        </b-skeleton-wrapper>
      </div>

      <div class="row">
        <div class="col-md">
          <button class="btn btn-success float-end" @click="postDoc()">
            บันทึก
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal
      ref="modalImg"
      id="modalImg"
      title="ภาพประกอบ"
      hide-footer
      size="l"
      centered
    >
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-12 align-items-center">
            <div class="row align-items-center">
              <img
                :src="this.imagePath"
                class="preview-img"
                @click="singleImage = true"
              />
              <vue-easy-lightbox
                :visible="singleImage"
                :imgs="this.imagePath"
                @hide="singleImage = false"
              ></vue-easy-lightbox>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- end col -->
  </Layout>
</template>
<script>
import {
  required,
  //   maxValue,
  //   minValue,
  //   maxLength,
  //   numeric,
} from "vuelidate/lib/validators";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
import DatePicker from "vue2-datepicker";
import ChangeDate from "@/components/changeDateComponent";
import documentComponent from "@/components/documentComponent";

/**
 * Form validation component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    ChangeDate,
    documentComponent,
  },
  page: {
    title: appConfig.deliverDoc,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      apiShow: "api/reg-send-reg-dept/show",
      apiImg: "api/reg-send-reg-dept/full-size-image",
      apiCheck: "api/reg-send-reg-dept/received-doc",
      isReceived: "ส่งเอกสาร",
      receivedBy: "ผู้ส่ง",
      overlayFlag: false,
      dismissSecs: 3,
      resalert: "",
      dismissCountDown: 0,
      loading: undefined,
      message: "โปรดตรวจสอบข้อมูล",
      title: "แก้ไขการส่งเอกสาร",
      items: [
        {
          text: "งานทะเบียน",
          active: true,
        },
        {
          text: "ส่งเอกสารให้แผนกทะเบียน",
          href: "/deliver-doc",
        },
        {
          text: "แก้ไขการส่งเอกสาร",
          active: true,
        },
      ],
      senddId: "",
      rowsDocSend: [],
      optionsDoc: [],
      chkDoc: [],
      rowDocCus: [],
      optionCus: [],
      localData: [],
      localDataBranchUser: [],
      localDataBranchIdUser: [],
      optionsYear: [],
      rowDocType: [],
      filter: {
        regDate: "",
        customerFamilyNameTh: "",
        customerNameTh: "",
        docTypeId: "",
        startDate: "",
        endDate: "",
        licensePlate: "",
        regCode: "",
      },
      fields: [
        {
          key: "index",
          label: "ลำดับ",
        },
        {
          key: "docTypeNameTh",
          label: "ประเภทเอกสาร",
        },
        {
          key: "fileId",
          label: "รูปภาพ/ไฟล์",
        },
        {
          key: "isReceived",
          label: "ได้ส่งเอกสาร",
        },
        {
          key: "receivedDate",
          label: "วันที่",
        },

        {
          key: "receivedBy",
          label: "ผู้ส่ง",
        },
      ],
      fieldsSelect: [
        {
          key: "index",
          label: "ลำดับ",
        },
        {
          key: "docCode",
          label: "เลขที่นำส่งเอกสาร",
        },
        {
          key: "licensePlate",
          label: "เลขทะเบียน",
        },
        {
          key: "vin",
          label: "เลขตัวถัง",
        },

        {
          key: "modelNameTh",
          label: "รุ่นรถ",
        },

        {
          key: "customerNameTh",
          label: "ชื่อลูกค้า",
        },
        {
          key: "customerFamilyNameTh",
          label: "นามสกุลลูกค้า",
        },

        {
          key: "action",
          label: "",
        },
      ],
      optionSale: [
        { value: "1", text: "รถใหม่" },
        { value: "0", text: "รถต่อทะเบียน" },
      ],

      document: [],
      singleImage: false,
      imagePath: "",
      regJobId: this.$route.params.regJobId,
      status: "",
      branchIdSearch: "",
      branchIdRo: "",
      rowRo: [],
      page: "",
      totalPage: "",
      perPage: 10,
      currentPage: 1,
      rowpage: "",
      totalRecord: "",
      rowTest: [],
      totalRows: 1,
      rowParts: [],
      pageOptions: [5, 10, 25, 50, 100],
      selectMode: "single",
      selected: [],
      isLoading: "",
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      optionsGroupId: [],
      optionsUnits: [],
      options: [],
      rowRegDoc: [],
      rowPayment: [],
      optionDoc: [],
      optionInvoice: [],
      isDisabled: false,
      rowsVehi: [],
      rowsCus: [],
      optionTypeDoc: [],
      form: {
        branchId: "",
        sendDate: "",
        note: "",
        status: "",
        customerName: "",
        customerFullNameTh: "",
        isSale: "",
        cusId: "",
        vehicleId: "",
        regJobCode: "",
        paymentId: "",
      },
      searchform: {
        doc: "",
      },

      submitform: false,
      submit: false,
    };
  },

  validations: {
    form: {
      branchId: {
        required,
      },

      sendDate: {
        required,
      },
      // cause: {
      //   required,
      // },
      // customerDecision: {
      //   required,
      // },
    },
  },
  computed: {},
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
      if (user.branchId == item.branchId) {
        this.form.branchId = { branchId: item.branchId, nameTh: item.nameTh };
      }
    });
    this.localDataBranchIdUser = arrayBranch;
    this.branchId = user.branchId;
    this.localData = user.userAccessBranch;
    // this.filter.branch = {branchId: user.branchId}
    // //(user.branchId);
    this.getDataShow();
  },
  created() {
    // this.getLocalData();
  },
  methods: {
    customLabelCus({ nameTh, familyNameTh }) {
      return `${nameTh != null ? nameTh : ""}  ${
        familyNameTh != null ? familyNameTh : " "
      }`;
    },
    customLabelPay({ paymentCode }) {
      return `${paymentCode != null ? paymentCode : " "}`;
    },
    customLabelVi({ licensePlate }) {
      return `${licensePlate != null ? licensePlate : " "}`;
    },
    customLabelReg({ regCode }) {
      return `${regCode != null ? regCode : " "}`;
    },
    onRowSelected(rowRo) {
      this.selected = rowRo[0];
      var roCode = { roCode: this.selected.roCode, roId: this.selected.roId };
      var customerFullNameTh = {
        nameTh: `${
          this.selected.customerNameTh != null
            ? this.selected.customerNameTh
            : ""
        } ${
          this.selected.customerFamilyNameTh != null
            ? this.selected.customerFamilyNameTh
            : ""
        }`,
        cusId: this.selected.cusId != null ? this.selected.cusId : "",
      };
      var vehicleId = this.selected.vehicleId;
      var customerMolbileNumber = this.selected.mobilePhone;
      // var branchName = { nameTh: this.selected.branchName };
      this.form.roId = roCode;
      this.form.customerFullNameTh = customerFullNameTh;
      this.form.vehicleId = vehicleId;

      this.form.customerMolbileNumber = customerMolbileNumber;
      // this.form.branchId = branchName;
      this.$refs["modalRo"].hide();
    },
    showModal(data) {
      this.docId = data.docId;
      this.docTypeNameTh = data.docTypeNameTh;
      this.$refs["modalUploadImg"].show(this.docId);
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchId = user.branchId;

      const localDataBranch = [];
      const localDataBranchId = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item), localDataBranchId.push(item.branchId);
      });
      this.localDataBranchUser = localDataBranch;
      this.localDataBranchIdUser = localDataBranchId;
      this.getRo();
    },
    getDataloadImage: function (data) {
      //  console.log(data);
      // this.regJobId = data.regJobId;
      this.fileId = data.fileId;
      // this.overlayFlag = true;
      // this.loading = true; //skeleton true
      useNetw
        .get(this.apiImg, {
          params: {
            regJobId: atob(this.regJobId),
            fileId: this.fileId,
          },
        })
        .then((response) => {
          //(this.imagePath);
          this.rowImg = response.data.data;
          this.imagePath = response.data.data.filePath;
          this.$refs["modalImg"].show();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          // this.loading = false; //skeleton false
        });
    },
    getDataShow: function () {
      this.overlayFlag = true;
      useNetw
        .get("api/reg-send-reg-dept/show", {
          params: {
            regJobId: atob(this.regJobId),
          },
        })
        .then((response) => {
          this.rowData = response.data.data;
          this.form.sendDate = response.data.data.regJobDate;
          this.form.branchId = {
            nameTh: response.data.data.branchName,
            branchId: response.data.data.branchId,
          };

          this.form.note = response.data.data.note;
          this.form.status = response.data.data.status;
          this.form.doc = response.data.data.docCode;
          this.form.isSale = response.data.data.isSale;
          this.form.regJobCode = response.data.data.regJobCode;

          this.form.customerFullNameTh = `${
            response.data.data.customerNameTh
          } ${
            response.data.data.customerFamilyNameTh != null
              ? response.data.data.customerFamilyNameTh
              : ""
          }`;
          this.form.vehicleId = response.data.data.licensePlate;

          this.form.vin = response.data.data.vin;
          this.form.modelNameTh = response.data.data.modelNameTh;
          this.form.paymentId = response.data.data.paymentCode;
          this.form.regCode = response.data.data.regCode;

          this.document = response.data.data.document;
          // console.log(this.document);
          // this.getDocCus();
          // this.getDocSend();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message + "<br>" + err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getDocCus: function () {
      this.loading = true;
      // this.branchIdRo = branchId;
      useNetw
        .get(this.apiShow, {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId:
              this.form.branchId !== null
                ? this.form.branchId.branchId
                : this.branchId,
            regCode: this.filter.regCode,
            customer: this.filter.customerNameTh,
            docTypeId:
              this.filter.docTypeId != null
                ? this.filter.docTypeId.docTypeId
                : this.filter.docTypeId,
            startDate: this.filter.startDate,
            endDate: this.filter.endDate,
            licensePlate: this.filter.licensePlate,
          },
        })
        .then((response) => {
          //(response);
          this.rowDocCus = response.data.data;

          const per_Page = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / per_Page);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response.data.message),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.loading = false;
          // this.overlayFlag = false;
        });
    },
    getDocType: function () {
      this.loading = true;
      // this.branchIdRo = branchId;
      useNetw
        .get("api/reg-send-msg/dealer-document-type", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
          },
        })
        .then((response) => {
          this.rowDocType = response.data.data;
          const per_Page = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / per_Page);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response.data.message),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    getDocSend: function () {
      this.isLoading = true;
      useNetw
        .get("api/reg-send-reg-dept/doc", {
          params: {
            regJobId: atob(this.regJobId),
          },
        })
        .then((response) => {
          this.rowsDocSend = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
          // this.$router.push("/login");
        })
        .then(() => {
          this.isLoading = false;
        });
    },

    getParts: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/part-lost-sale/branch-part", {
          params: {
            page: this.currentPage,
            perPage: 100, //เฉพาะค้นหา สูงสุด 100
            "branchId[]":
              this.form.branchId != null
                ? this.form.branchId.branchId
                : this.localDataBranchIdUser,
          },
        })
        .then((response) => {
          this.rowParts = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getDataVehicles: function () {
      this.isLoading = true;
      useNetw
        .get("api/part-lost-sale/vehicle", {
          params: {
            page: 1,
            perPage: 100,
            branchId: this.localDataBranchIdUser,
          },
        })
        .then((response) => {
          this.rowsVehi = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            // this.$router.push("/login");
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    formSend() {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.form.$invalid != true) {
        this.updateSubmit();
      }
    },
    updateSubmit() {
      useNetw
        .put("/api/reg-send-reg-dept/update", {
          regJobId: atob(this.regJobId),
          sendDate: this.form.sendDate,
          note: this.form.note,
        })

        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })

        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },

    postDoc() {
      //(this.chkDoc);
      useNetw
        .post("api/reg-send-reg-dept/doc/store", {
          regJobId: atob(this.regJobId),
          docId: this.chkDoc,
        })

        .then((response) => {
          this.senddId = response.data.senddId;
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.chkDoc = [];
          this.$refs["modalDoc"].hide();
          this.getDataShow();
        })

        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },

    handleSearch() {
      if (this.branchIdSearch === "") {
        this.branchId;
      } else {
        this.branchId = this.branchIdSearch.branchId;
      }
      this.getDocCus();
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getDocCus();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getDocCus();
    },
    delete: function (rowItemId, index) {
      this.rowsDocSend.splice(index, 1);
      this.loading = true; //skeleton true
      useNetw
        .delete("api/reg-send-reg-dept/doc/delete", {
          data: {
            senddId: rowItemId,
          },
        })
        .then((response) => {
          this.getDataShow();
          // alert("ต้องการลบหรือไม่")

          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.validationMessage),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.getDocSend();
          // this.overlayFlag = false;

          this.loading = false; //skeleton false
        });
    },
    alert(rowItem, index) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบหรือหรือไม่ ?",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.delete(rowItem, index);
          }
        });
    },
    putSubmit() {
      useNetw
        .put("api/reg-send-reg-dept/submit", {
          regJobId: atob(this.regJobId),
        })

        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );

          this.$router.push({ name: "deliverDoc" });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(response) {
      this.dismissCountDown = this.dismissSecs;
      this.resalert = response;
    },
    putCheck(docId) {
      useNetw
        .put(this.apiCheck, {
          docId: docId.docId,
        })

        .then((response) => {
          this.getDataShow();
          this.showAlert(response.data.message);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$router.push({ name: "purchase-order" });
          // this.overlayFlag = false;
        });
    },
    alertSubmit() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการยืนยันหรือไม่ !!",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.putSubmit();
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "ยกเลิกเรียบร้อยแล้ว",
              "error"
            );
          }
        });
    },
  },
  middleware: "authentication",
};
</script>
 
<style lang="scss" scoped>
.preview-img {
  max-width: 200px;
}
@media only screen and (min-width: 500px) {
  .alert-fixed {
    position: fixed;
    top: 50px;
    left: 65%;
    width: 30%;
    z-index: 9999;
    border-radius: 0px;
  }
}
</style>
 

